import React from 'react'
import ReactMarkdown from 'react-markdown'
import { graphql } from 'gatsby'

import { Wrapper, Grid, H, Image, Divider, P } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

import PATHS from 'paths'
import {
  TRUSTPILOT_RATING_OUT_OF_5,
  TRUSTPILOT_REVIEWS_TOTAL,
  TELEPHONE_WILLS_TELEPHONE_NUMBER,
  TELEPHONE_WILLS_APPOINTMENT_BOOKING_URL,
} from 'config'
import { useRecordGAPageview } from 'lib/hooks/useRecordGAPageview'
import PartnerLandingPageLayout from 'layouts/PartnerLandingPageLayout'
import { roundDownToNearestHundred } from 'lib/formatting/numbers'
import { PRODUCTS } from 'lib/products/constants'
import TrustpilotReviews from 'components/TrustpilotWidget/TrustpilotReviews'
import TelephoneNumber from 'components/TelephoneNumber'
import LinkButton from 'components/LinkButton'

import TelephoneWillsWhatsIncluded from './TelephoneWillWhatsIncluded'

interface PartnerTelephoneProps {
  data: {
    page: {
      utmCampaign: string
      utmMedium: string
      bannerText: { bannerText: string }
      partner: {
        bannerBackgroundColor: string
        bannerLogoAlignment: 'left' | 'right'
        bannerText: { bannerText: string }
        bannerTextColor: string
        logo: { file: { url: string }; title: string }
        utmSource: string
        utmChannel: string
        telephoneWillsEnabled: boolean
        hideFarewillLogoInHeader: boolean | null
        partnerId: string
      }
      termsAndConditions: { termsAndConditions: string } | null
      heroTitle: string
      heroSubtitle: { heroSubtitle: string }
    }
  }
}

const PartnerTelephone = ({
  data: {
    page: {
      utmCampaign,
      utmMedium,
      bannerText,
      partner,
      termsAndConditions,
      heroTitle,
      heroSubtitle,
    },
  },
}: PartnerTelephoneProps): React.ReactElement => {
  useRecordGAPageview('PartnerLandingPageView', {
    partner: partner.utmSource,
  })
  return (
    <PartnerLandingPageLayout
      bannerText={bannerText?.bannerText}
      title="Contact | Wills"
      description={`Write a will online from the comfort of your own home with the UK’s number one will specialists. Rated ${TRUSTPILOT_RATING_OUT_OF_5} out of 5 by over ${roundDownToNearestHundred(
        TRUSTPILOT_REVIEWS_TOTAL
      )} customers on Trustpilot.`}
      product={PRODUCTS.TELEPHONE_WILLS}
      canonicalPath={PATHS.WILLS.OVERVIEW}
      meta={[
        {
          name: 'robots',
          content: 'noindex',
        },
      ]}
      utmCampaign={utmCampaign}
      utmMedium={utmMedium}
      partner={partner}
      termsAndConditions={termsAndConditions}
    >
      <Wrapper background={COLOR.WHITE}>
        <Grid container>
          <Grid.Item spanFromL={5}>
            <H tag="h1" size="XL" decorative>
              {heroTitle || 'Let’s get your will sorted'}
            </H>
            <Wrapper margin={[0, 0, 'XL']}>
              {heroSubtitle?.heroSubtitle ? (
                <ReactMarkdown>{heroSubtitle.heroSubtitle}</ReactMarkdown>
              ) : (
                <>
                  <P>
                    Book a 15 min call with one of our will specialists. They’ll
                    call you to chat about your situation and help you find the
                    right service for you.
                  </P>
                  <P>
                    You’ll need an email address to book the call online. Or you
                    can call us during opening hours.
                  </P>
                </>
              )}
            </Wrapper>
            <Wrapper margin={[0, 0, 'XL']}>
              <LinkButton
                stretch
                wide={false}
                to={TELEPHONE_WILLS_APPOINTMENT_BOOKING_URL}
              >
                Book my free callback
              </LinkButton>
              <Wrapper
                centered
                bordered
                borderRadius="S"
                margin={['S', 0, 0]}
                padding="M"
              >
                <P color={COLOR.BLACK}>
                  Or call us now{' '}
                  <TelephoneNumber
                    telephoneNumber={TELEPHONE_WILLS_TELEPHONE_NUMBER}
                    underline
                    preventSwapping={false}
                  />
                </P>
              </Wrapper>
            </Wrapper>
          </Grid.Item>

          <Grid.Item
            startColumnFromL={7}
            spanFromL={6}
            style={{ justifySelf: 'center' }}
          >
            <Image
              path="illustrations/cropped/blob-with-telephone"
              width={180}
              widthFromL={400}
              stretch
            />
          </Grid.Item>
        </Grid>

        <Divider container />

        <Wrapper container containerPaddingBottom={0}>
          <TrustpilotReviews showTitle />
        </Wrapper>

        <TelephoneWillsWhatsIncluded />
      </Wrapper>
    </PartnerLandingPageLayout>
  )
}

export const query = graphql`
  query PartnerTelephone($slug: String!) {
    page: contentfulPartnerCampaignPage(slug: { eq: $slug }) {
      ...CoBrandedBannerFields
      utmCampaign
      utmMedium
      heroTitle
      heroSubtitle {
        heroSubtitle
      }
      partner {
        utmSource
        utmChannel
        telephoneWillsEnabled
        hideFarewillLogoInHeader
        partnerId
      }
    }
  }
`

export default PartnerTelephone
